/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"
import { Helmet } from "react-helmet"

const Layout = ({ children }) => {

  const [ darkMode, setDarkMode ] = React.useState('')

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className={ `bg-white dark:bg-gray-400`}>
      <Helmet bodyAttributes={{ class: darkMode ? 'dark' : '' }}>
        <link
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
          rel="stylesheet"
        />
      </Helmet>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} darkMode={ darkMode } onSetDarkMode={(bool) => setDarkMode(bool)} />
      <div
        className={`
          container
          mx-auto
          px-4
          my-4
        `}
      >
        <main>{children}</main>
      </div>
      <footer
        className='px-4 py-8 text-center bg-gray-100 text-xs'
      >
        © {new Date().getFullYear()}, The S2 Underground Project
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
